<template>
  <div class="bulletin-ddetail-edit">
    <AiHintBlock
      v-if="!get(route, 'params.id')"
      :hintLoading="hintLoading"
      @confirm="hintConfirm"
    />
    <BaseElForm ref="formRef" label-position="top" :rules="formRules" :model="formData">
      <div class="form-blocks-wrapper">
        <BulletinDetailInfoBlock v-loading="hintLoading" :formData.sync="formData" />
        <BulletinDetailContentBlock v-loading="hintLoading" :formData.sync="formData" />
      </div>
    </BaseElForm>

    <PageFixedFooter
      @confirm="onSubmit"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, onBeforeMount, set } from 'vue'
import BulletinDetailInfoBlock from './components/BulletinDetailInfoBlock.vue'
import BulletinDetailContentBlock from './components/BulletinDetailContentBlock.vue'
import { get, map, pick, some } from 'lodash'
import { isDigitRules, noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import notifyMessage from '@/config/notifyMessage'
import { useRoute, useRouter } from 'vue-router/composables'
import { CreateClientAnnouncement, FindClientAnnouncement, UpdateClientAnnouncement, CheckBindMemberCenterAd } from '@/api/bulletin'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { simpleSyncFormData } from '@/use/useForm'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { hint } from '@/config/hint'

export default defineComponent({
  name: 'MemberCenterBulletinDetailEdit',
  components: { BulletinDetailInfoBlock, BulletinDetailContentBlock, AiHintBlock },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const formRef = ref(null)
    const formData = reactive({
      publishedStartAt: null,
      publishedEndAt: null,
      contentBlocks: [],
      binded: false,
      content: {
        title: null,
        banner: null,
        syncPublishDate: true,
        activityStartAt: null,
        activityEndAt: null,
        enabledProgressBar: false,
        progressBarSettings: {
          title: null,
          startAt: null,
          endAt: null,
          syncPublishDate: true,
          displayTimeRange: true,
          source: null,
          countBy: null, // amount / itemLength:
          goalCount: null,
        },
      },
    })
    const formRules = computed(() => {
      const rules = {
        publishedStartAt: [noEmptyRules()],
        contentBlocks: [noEmptyRules()],
        'content.title': [noEmptyRules()],
        'content.progressBarSettings.goalCount': [noEmptyRules(), isDigitRules()],
        'content.progressBarSettings.source': [noEmptyRules()],
        'content.progressBarSettings.countBy': [noEmptyRules()],
      }
      return rules
    })
    const { configurationHint, hintLoading, setData } = useAi()
    const hintConfirm = async (payload) => {
      const res = await configurationHint(get(hint, 'clientAnnouncement.key'), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData.content, res)
      const publishedStartAt = get(res.hint, 'publishedStartAt')
      const publishedEndAt = get(res.hint, 'publishedEndAt')
      formData.publishedStartAt = publishedStartAt
      formData.publishedEndAt = publishedEndAt
      formData.content.syncPublishDate = publishedStartAt === get(formData, 'content.activityStartAt') && publishedEndAt === get(formData, 'content.activityEndAt')
      formData.content.progressBarSettings.syncPublishDate = publishedStartAt === get(formData, 'content.progressBarSettings.startAt') && publishedEndAt === get(formData, 'content.progressBarSettings.endAt')
      formData.contentBlocks = map(get(res.hint, 'contentBlocks'), block => {
        return {
          type: block.type,
          title: block.title,
          content: block.htmlContent,
        }
      })
    }
    const submitData = computed(() => {
      const enabledProgressBar = get(formData, 'content.enabledProgressBar')
      const bulletinId = get(route, 'params.id')
      return {
        title: get(formData, 'content.title'),
        id: bulletinId || undefined,
        publishedStartAt: get(formData, 'publishedStartAt'),
        publishedEndAt: get(formData, 'publishedEndAt'),
        activityStartAt: get(formData, 'content.activityStartAt'),
        activityEndAt: get(formData, 'content.activityEndAt'),
        enabledProgressBar,
        progressBarSettings: enabledProgressBar ? {
          title: get(formData, 'content.progressBarSettings.title') || null,
          startAt: get(formData, 'content.progressBarSettings.startAt') || null,
          endAt: get(formData, 'content.progressBarSettings.endAt') || null,
          displayTimeRange: get(formData, 'content.progressBarSettings.displayTimeRange'),
          source: get(formData, 'content.progressBarSettings.source'),
          countBy: get(formData, 'content.progressBarSettings.countBy'),
          goalCount: get(formData, 'content.progressBarSettings.goalCount'),
        } : undefined,
        contentBlocks: map(get(formData, 'contentBlocks'), (block) => {
          return {
            type: block.type,
            title: block.title,
            imageAction: {
              type: block.action || 'none',
              link: block.action === 'link' ? block.link : null,
            },
            htmlContent: block.content,
            ImageId: get(block.img, 'id'),
          }
        }),
        ImageId: get(formData, 'content.banner.id'),
      }
    })

    const checkTextBlockContent = () => {
      return some(formData.contentBlocks, (item) => {
        if (item.type === 'html') {
          if (!item.content) {
            window.$message.warning('需填寫圖文訊息內文')
            return true
          }
        }
        if (item.type === 'image') {
          if (!item.img) {
            window.$message.warning('需填寫圖文訊息圖片')
            return true
          }
        }
        return false
      })
    }

    const onSubmit = async () => {
      if (!await formUtils.checkForm(formRef.value)) {
        return window.$message.warning(notifyMessage.formDataError)
      }
      if (checkTextBlockContent()) return

      let apiMethod = CreateClientAnnouncement

      if (route.params.id) apiMethod = UpdateClientAnnouncement

      await simpleFetch(apiMethod, {
        shopId: shopId.value,
        ...submitData.value,
      }, (res) => {
        window.$message.success(notifyMessage.createSuccess)
        router.push({ name: 'MemberCenterBulletinDetailList' })
      })
    }

    const onCancel = () => {
      router.push({ name: 'MemberCenterBulletinDetailList' })
    }

    onBeforeMount(async () => {
      if (route.params.id) {
        simpleFetch(CheckBindMemberCenterAd, { shopId: shopId.value, id: route.params.id }, (res) => {
          formData.binded = res.exist
        })
        simpleFetch(FindClientAnnouncement, { shopId: shopId.value, id: route.params.id }, (res) => {
          simpleSyncFormData(pick(res, ['publishedStartAt', 'publishedEndAt']), formData)
          simpleSyncFormData(pick(res, ['activityStartAt', 'activityEndAt', 'title', 'enabledProgressBar']), formData.content)
          formData.content.banner = get(res, 'Image')

          simpleSyncFormData(get(res, 'progressBarSettings'), formData.content.progressBarSettings)
          formData.content.syncPublishDate = get(formData, 'publishedStartAt') === get(formData, 'content.activityStartAt') && get(formData, 'publishedEndAt') === get(formData, 'content.activityEndAt')
          formData.content.progressBarSettings.syncPublishDate = get(formData, 'publishedStartAt') === get(formData, 'content.progressBarSettings.startAt') && get(formData, 'publishedEndAt') === get(formData, 'content.progressBarSettings.endAt')
          // formData.content.syncPublishDate = false
          // formData.content.progressBarSettings.syncPublishDate = false

          formData.contentBlocks = map(res.contentBlocks, block => {
            return {
              type: block.type,
              title: block.title,
              img: block.Image,
              content: block.htmlContent,
              action: block.imageAction.type,
              link: block.imageAction.link,
            }
          })
        })
      }
    })

    return { formData, formRules, formRef, onSubmit, submitData, onCancel, route, get, hintConfirm, hintLoading }
  },
})
</script>

<style lang="postcss" scoped>
.form-blocks-wrapper {
  @apply flex flex-col gap-[20px] m-auto;
}
</style>
