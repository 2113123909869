<template>
  <div class="side-highlight-container">
    <p class="card-title">資訊頁設定</p>
    <BaseElFormItem label="封面圖">
      <UploadButton
        :img="syncForm.content.banner"
        width="160px"
        height="90px"
        @change="imageChange"
      />
    </BaseElFormItem>

    <BaseElFormItem label="資訊頁標題" prop="content.title">
      <BaseElInput v-model="syncForm.content.title" clearable />
    </BaseElFormItem>
    <BaseElFormItem>
      <template slot="label">
        <FormItemTooltipLabel label="活動走期 / 資訊更新日期">
          顯示在佈告資訊頁的日期
        </FormItemTooltipLabel>
      </template>
      <div>
        <BaseElCheckbox v-model="syncForm.content.syncPublishDate" @change="onSyncPublishDate">如同公開期間</BaseElCheckbox>
        <p v-show="syncForm.content.syncPublishDate">{{ displayDefaultDateTime }}</p>
        <div v-show="syncForm.publishedStartAt && !syncForm.content.syncPublishDate" class="flex w-full gap-[8px] max-w-[560px]">
          <BaseElFormItem prop="content.activityStartAt">
            <el-date-picker
              v-model="syncForm.content.activityStartAt"
              editable
              type="datetime"
              placeholder="開始時間"
            />
          </BaseElFormItem>
          <p>至</p>
          <BaseElFormItem prop="content.activityEndAt">
            <el-date-picker
              v-model="syncForm.content.activityEndAt"
              editable
              type="datetime"
              placeholder="結束時間 (未填則為不限期)"
            />
          </BaseElFormItem>
        </div>
        <p v-show="!syncForm.publishedStartAt">請先選擇公開期間</p>
      </div>
    </BaseElFormItem>

    <el-divider />

    <BaseElFormItem label="顯示自定義進度條" prop="content.enabledProgressBar">
      <BaseElSwitch
        v-model="syncForm.content.enabledProgressBar"
        inactive-text="隱藏"
        active-text="顯示"
      />
      <CustomProgressEditBlock
        v-if="syncForm.content.enabledProgressBar"
        :formData.sync="syncForm"
      />
    </BaseElFormItem>

    <el-divider />

    <BaseElFormItem label="圖文訊息設置" prop="contentBlocks">
      <div>
        <ImageContentEditArea
          :contentData="syncForm.contentBlocks"
          @change="onContentListChange"
        />
      </div>
    </BaseElFormItem>

    <ImageCropper
      v-if="modal.imgCropper"
      :image="imgHolder"
      :ratio="[16, 9]"
      @close="modal.imgCropper = false"
      @uploaded="getCroppedImage"
    />
  </div>
</template>

<script>

import { defineComponent, reactive, ref, set, computed } from 'vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ImageContentEditArea from './ImageContentEditArea.vue'
import CustomProgressEditBlock from './CustomProgressEditBlock.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { formatDate, isInvalidDate } from '@/utils/date'
import { get } from 'lodash'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'BulletinDetailContentBlock',
  components: { UploadButton, ImageCropper, ImageContentEditArea, CustomProgressEditBlock, FormItemTooltipLabel },
  props: { formData: { type: Object, default: () => ({}) } },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'formData', emit)
    const imgHolder = ref(null)
    const modal = reactive({
      imgCropper: false,
    })

    const getCroppedImage = (e) => {
      syncForm.value.content.banner = e
      modal.imgCropper = false
    }

    const imageChange = (img, level) => {
      imgHolder.value = img
      modal.imgCropper = true
    }

    const onContentListChange = (newList) => {
      set(syncForm.value, 'contentBlocks', newList)
    }

    const onSyncPublishDate = (toggle) => {
      console.log()
      if (!toggle) return
      syncForm.value.content.activityStartAt = syncForm.value.publishedStartAt
      syncForm.value.content.activityEndAt = syncForm.value.publishedEndAt
    }

    const displayDefaultDateTime = computed(() => {
      const start = formatDate(get(syncForm.value, 'publishedStartAt'), 'YYYY-MM-DD HH:mm')
      const end = formatDate(get(syncForm.value, 'publishedEndAt'), 'YYYY-MM-DD HH:mm')
      return `${start} ~ ${isInvalidDate(end) ? '不限期' : end}`
    })

    return { syncForm, getCroppedImage, imageChange, modal, imgHolder, onContentListChange, onSyncPublishDate, displayDefaultDateTime }
  },
})
</script>

<style lang="postcss" scoped>
.el-form-item, .el-date-editor.el-input {
  @apply w-full
}
</style>
